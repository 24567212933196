<template>
	<el-dialog title="" :visible.sync="showVisible" width="578px" center :close-on-click-modal="false">
		<Table
			:table-data="tableData"
			:table-head="tableHead"
			:is-show-selection="false"
			:isShowPage="false"
			:showOperation="false"
			:tableHeight="tableHeight"
		>
		</Table>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			showVisible: false,
			tableHead: [
				{
					label: '姓名',
					prop: 'title',
					formatter: (row) => {
						return row.title || '-';
					},
				},
				{
					label: '联系方式',
					prop: 'code',
					formatter: (row) => {
						return row.code ? row.code : '-';
					},
				},
			],
			tableData: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-table {
	margin-bottom: 20px;
}
</style>

